module.exports = function (ngModule) {
  // firebase
  ngModule.constant('FB_AUTH_DOMAIN', process.env.FB_AUTH_DOMAIN);
  ngModule.constant('FB_URL', process.env.FB_URL);
  ngModule.constant('FB_API', process.env.FB_API_KEY);

  // elastic search
  ngModule.constant('ES_URL', process.env.ELASTIC_SEARCH_URL);
  ngModule.constant('ES_PORT', 9243);
  ngModule.constant('ES_INDICES', {
    logs: 'fr-prod-logs',
    verifications: 'fr-prod-verifications',
    notifications: 'fr-prod-notifications',
    ingredients: 'fr-prod-ingredients',
    //messages: 'fr-prod-messages',
    orders: 'fr-prod-orders',
    users: 'fr-prod-users',
    equipment: 'fr-prod-equipment',
    rfqs: 'fr-prod-rfqs',
    sops: 'fr-prod-sops',
    batches: 'fr-prod-batches',
    sopLogs: 'fr-prod-sop-logs',
    hazards: 'fr-prod-hazards',
    leads: 'fr-prod-leads',
    suppliers: 'fr-prod-suppliers',
    supplierIngredients: 'fr-prod-supplier-ingredients',
    productGroups: 'fr-prod-product-groups',
    organizations: 'fr-prod-organizations',
    products: 'fr-prod-products',
    sopLibraryItems: 'fr-prod-sop-library-items',
    recallEvents: 'fr-prod-recall-events',
    foodProducts: 'fr-prod-food-products',
    checklists: 'fr-prod-checklists',
    checklistTemplates: 'fr-prod-checklist-templates',
    batchLogs: 'fr-prod-batch-logs',
  });

  // stripe
  ngModule.constant('STRIPE_PUBLIC_KEY', process.env.STRIPE_PUBLIC_KEY);
  ngModule.constant('CANCEL_SUBSCRIPTION_PROMO', 'cancel_discount');

  // S3
  ngModule.constant('S3_URL', process.env.S3_URL);

  // foodready specifics
  ngModule.constant('SAMPLE_PRODUCT_ID', '-KW8sH0h5jPd4pCN4i06');
  ngModule.constant('SAMPLE_ORGANIZATION_ID', '-KW8navHpZ48rRmo2e8w');
  ngModule.constant('CF_ORGANIZATION_ID', '-K01rssgSuIXIrX2hFG7');
  ngModule.constant('CF_CHANNEL_ID', 'CFChannel');
  ngModule.constant('ENV', process.env.NODE_ENV);
  ngModule.constant('FR_SUPPORT_PHONE', '(844)-596-0962');

  // idle timeout (10 minutes)
  ngModule.constant('IDLE_DURATION', 120 * 60);
  ngModule.constant('IDLE_TIMEOUT', 5);

  // supplier invite expiration
  ngModule.constant('INVITE_EXPIRE_DAYS', 5);

  // Google recaptcha
  ngModule.constant('CAPTCHA_KEY', process.env.CAPTCHA_KEY);

  // GoJs License Key
  ngModule.constant(
    'GO_LICENSE',
    '73f944e5b56031b700ca0d2b113f69ee1bb37b619e821ef85e5642f4ef0a68442b98ec2803d78f94d4a85cec1d2890d9dd953' +
      'a7ec31c073de73683d946e68ffcb63675b4150f40dca65175979cfa79f3ff2d76a1d0a571f78a7e8ca0bb' +
      'a9d18c5fe9f0d757cd11b829'
  );

  // Fake org to hold staged files from the file upload portal
  ngModule.constant('FILE_PORTAL_ORG', require('../../../config/constants/miscConstants').UPLOAD_PORTAL_DUMMY_ORG);

  // Google Analytics ID
  // ngModule.constant('GTAG_ID', 'UA-177953019-1');
  ngModule.constant('GAD_ID', 'AW-513292474');

  ngModule.constant('UETTAG_ID', '56300375');
  ngModule.constant('GTM_ID', 'GTM-WL8X9T9');

  // LinkedIn Insight Tag
  ngModule.constant('LINKEDIN_PARTNER_ID', '4335049');

  // Profitwell Public Key
  ngModule.constant('PROFITWELL_PUBLIC_KEY', '565eb5f2ccb7b2e87508e77e0db1166b');
  ngModule.constant('V2_URL', process.env.V2_URL);
  ngModule.constant('LOCAL_DEVELOPMENT', process.env.LOCAL_DEVELOPMENT);
};
